<template>
    <v-container fluid>
        <v-dialog
            persistent
            max-width="960px"
            transition="dialog-bottom-transition"
            v-model="dialogForm"
        >
            <v-form @submit.prevent="saveDealStep">
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                    <v-card flat>
                        <v-card-text class="py-0">
                            <v-row>
                                <v-col cols="12">
                                    <v-row class="d-flex mt-5">
                                        <v-col cols="10" class="ma-auto">
                                            <span class="title_menu">{{ heading }}</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex justify-end">
                                            <v-icon @click="closeDialogAdd" :title="$t('close')" color="primary" >mdi-close</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="mt-2">
                                <v-col class="py-0" cols="12">
                                    <v-tabs v-model="tab" background-color="transparent"
                                            :right="$vuetify.breakpoint.mdAndUp"
                                            :grow="$vuetify.breakpoint.smAndDown"
                                            color="deep-purple accent-4" @change="setLanguage" class="pb-4">
                                        <v-tab ref="language" v-for="language in languages" :key="language">
                                            <span :class="exist_translations[language] ? '' : 'red--text '">
                                                {{ language }}
                                            </span>
                                        </v-tab>
                                    </v-tabs>
                                </v-col>
                            </v-row>
                            <v-row class="mt-5">
                                <v-col class="py-0" cols="12" sm="12">
                                    <ValidationProvider ref="deal_step" rules="required|min:2|max:255"
                                                        v-slot="{ errors, valid }">
                                        <v-text-field v-model="deal_step" type="text" :disabled="loading"
                                                      :error="!valid" :error-messages="errors"
                                                      :label="$t('deal_step_name')"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12">
                                    <ValidationProvider ref="business_process" rules="required"
                                                        v-slot="{ errors, valid }">
                                        <v-select v-model="business_process"
                                                  :items="businessProcessItems" :error-messages="errors"
                                                  :error="!valid"
                                                  item-text="name" item-value="id"
                                                  :label="$t('business_process')"
                                                  @change="getDealSteps();getDealStatuses();getRequiredFields();"
                                                  outlined
                                                  solo
                                                  flat
                                                  dense
                                                  :disabled="loading"
                                                  :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                  color="primary"
                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                  clearable></v-select>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="12">
                                    <ValidationProvider ref="text" rules="min:1|max:255"
                                                        v-slot="{ errors, valid }">
                                        <v-text-field v-model="text" type="text" :disabled="loading"
                                                      :error="!valid" :error-messages="errors"
                                                      :label="$t('text_buttons')"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12">
                                    <v-autocomplete
                                        v-model="where_move"
                                        :disabled="isUpdating"
                                        :items="dealStepItems"
                                        chips
                                        :label="$t('deal_step')"
                                        item-text="name"
                                        item-value="id"
                                        multiple
                                        outlined
                                        solo
                                        flat
                                        dense
                                        :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                        color="primary"
                                        class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                        clearable
                                    >
                                        <template v-slot:selection="data">
                                            <v-chip
                                                v-bind="data.attrs"
                                                :input-value="data.selected"
                                                close
                                                @click="data.select"
                                                @click:close="remove(data.item)"
                                                class="input_chip"
                                            >
                                                <v-avatar left>
                                                    <v-icon :color="data.item.color">mdi-circle</v-icon>
                                                </v-avatar>
                                                {{ data.item.name }}
                                            </v-chip>
                                        </template>
                                        <template v-slot:item="data">
                                            <template v-if="typeof data.item !== 'object'">
                                                <v-list-item-content v-text="data.item"></v-list-item-content>
                                            </template>
                                            <template v-else>
                                                <v-list-item-avatar>
                                                    <v-icon :color="data.item.color">mdi-circle</v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                </v-list-item-content>
                                            </template>
                                        </template>
                                    </v-autocomplete>
                                </v-col>

                                <v-col class="py-0" cols="12">
                                    <v-autocomplete
                                        v-model="department"
                                        :disabled="isUpdating"
                                        :items="departmentItems"
                                        chips
                                        :label="$t('departments.head')"
                                        item-text="name"
                                        item-value="id"
                                        multiple
                                        outlined
                                        solo
                                        flat
                                        dense
                                        :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                        color="primary"
                                        class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                        clearable
                                    >
                                        <template v-slot:selection="data">
                                            <v-chip
                                                v-bind="data.name"
                                                :input-value="data.selected"
                                                close
                                                @click="data.select"
                                                @click:close="removeDepartment(data.item)"
                                                class="input_chip"
                                            >

                                                <v-avatar left>
                                                    <v-icon >mdi-circle</v-icon>
                                                </v-avatar>
                                                {{ data.item.name }}
                                            </v-chip>
                                        </template>
                                        <template v-slot:item="data">
                                            <template v-if="typeof data.item !== 'object'">
                                                <v-list-item-content v-text="data.item"></v-list-item-content>
                                            </template>
                                            <template v-else>
                                                <v-list-item-avatar>
                                                    <v-icon :color="data.item.color">mdi-circle</v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                </v-list-item-content>
                                            </template>
                                        </template>
                                    </v-autocomplete>
                                </v-col>

                                <v-col class="py-0" cols="12" v-if="department.length <= 0">
                                    <ValidationProvider ref="admin" rules="min:1" v-slot="{ errors, valid }">
                                        <v-combobox v-model="admin" :items="adminItems"
                                                    :error="!valid"
                                                    :search-input.sync="adminSearching" hide-selected
                                                    item-text="name" item-value="id" :loading="loadingAdmins"
                                                    :label="$t('responsible')" @click:clear="adminItems=[]"
                                                    @click="clearAdmins" @input="clearEmptyResponsibleItems(admin)"
                                                    multiple return-object autocomplete="off"
                                                    :error-messages="errors"
                                                    :disabled="loading"
                                                    outlined
                                                    solo
                                                    flat
                                                    dense
                                                    color="primary"
                                                    class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                    clearable>
                                            <template v-slot:item="{ attrs, item, parent, selected }">
                                                            <span class="search_list">
                                                                <span v-html="item.name"></span>
                                                                <span class="ml-2 hidden-is-empty" v-text="item.phone"></span>
                                                            </span>
                                            </template>
                                            <template v-slot:selection="{ attrs, item, parent, selected }">
                                                <v-chip v-if="item === Object(item)"
                                                        class="input_chip"
                                                        v-bind="attrs" :input-value="selected"
                                                        @click:close="parent.selectItem(item)"
                                                        :color="item.active ? 'green lighten-5' : 'red  lighten-5'"
                                                        :title="item.active ? $t('active') : $t('no_active')"
                                                        link close>
                                                    <span v-html="item.name"></span>
                                                </v-chip>
                                            </template>
                                            <template v-slot:no-data>
                                                <v-list-item>
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            {{ adminSearching ? $t('nothing_found_by', {'search': adminSearching}) : $t('nothing_found_name')}}
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </template>
                                        </v-combobox>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" >
                                    <ValidationProvider ref="deal_status" rules="min:1"
                                                        v-slot="{ errors, valid }">
                                        <v-select v-model="deal_status"
                                                  :items="deal_statusItems"
                                                  :error="!valid"
                                                  item-text="name" item-value="id"
                                                  :label="$t('deal_status')"
                                                  hide-details
                                                  :error-messages="errors"
                                                  :disabled="loading"
                                                  outlined
                                                  solo
                                                  flat
                                                  dense
                                                  color="primary"
                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                  clearable></v-select>
                                    </ValidationProvider>
                                </v-col>

                            </v-row>
                            <v-row class="pt-4">
                                <v-col cols="12" sm="6">
                                    <v-color-picker v-model="color" :disabled="loading"  mode="hexa"></v-color-picker>
                                </v-col>
                                <v-col class="py-0 my-0" cols="12" sm="6">
                                    <v-switch v-model="finish"
                                              class="py-0 my-0"
                                              :label="$t('finish')"
                                              color="primary" hide-details></v-switch>

                                    <v-switch v-model="required_filled"
                                              :label="$t('required_fields')"
                                              color="primary" hide-details></v-switch>
                                    <v-switch v-model="accompany_comment"
                                              :label="$t('accompany_comment')"
                                              color="primary" hide-details></v-switch>
                                </v-col>
                                <v-col class="py-0" cols="12">
                                    <v-autocomplete
                                        v-model="required_document"
                                        :disabled="isDocumentUpdating"
                                        :items="documentTypeItems"
                                        chips
                                        :label="$t('document_type')"
                                        item-text="name"
                                        item-value="id"
                                        multiple
                                        outlined
                                        solo
                                        flat
                                        dense
                                        :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                        color="primary"
                                        class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                        clearable
                                    >
                                        <template v-slot:selection="data">
                                            <v-chip
                                                v-bind="data.attrs"
                                                :input-value="data.selected"
                                                close
                                                @click="data.select"
                                                @click:close="removeDocumentType(data.item)"
                                                class="input_chip"
                                            >

                                                {{ data.item.name }}
                                            </v-chip>
                                        </template>
                                        <template v-slot:item="data">
                                            <template v-if="typeof data.item !== 'object'">
                                                <v-list-item-content v-text="data.item"></v-list-item-content>
                                            </template>
                                            <template v-else>
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                </v-list-item-content>
                                            </template>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col class="py-0" cols="12">
                                    <v-autocomplete
                                        v-model="required_tabs"
                                        :disabled="isRequiredTabUpdating"
                                        :items="requiredTabItems"
                                        chips
                                        :label="$t('required_tabs')"
                                        item-text="name"
                                        item-value="id"
                                        multiple
                                        outlined
                                        solo
                                        flat
                                        dense
                                        :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                        color="primary"
                                        class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                        clearable
                                    >
                                        <template v-slot:selection="data">
                                            <v-chip
                                                v-bind="data.attrs"
                                                :input-value="data.selected"
                                                close
                                                @click="data.select"
                                                @click:close="removeRequiredTabs(data.item)"
                                                class="input_chip"
                                            >

                                                {{ data.item.name }}
                                            </v-chip>
                                        </template>
                                        <template v-slot:item="data">
                                            <template v-if="typeof data.item !== 'object'">
                                                <v-list-item-content v-text="data.item"></v-list-item-content>
                                            </template>
                                            <template v-else>
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                </v-list-item-content>
                                            </template>
                                        </template>
                                    </v-autocomplete>
                                </v-col>

                                <v-col class="py-0" cols="12">
                                    <v-autocomplete
                                        v-model="required_fields"
                                        :disabled="isRequiredFieldUpdating"
                                        :items="requiredFieldItems"
                                        chips
                                        :label="$t('required_field')"
                                        item-text="name"
                                        item-value="id"
                                        multiple
                                        outlined
                                        solo
                                        flat
                                        dense
                                        :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                        color="primary"
                                        class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                        clearable
                                    >
                                        <template v-slot:selection="data">
                                            <v-chip
                                                v-bind="data.attrs"
                                                :input-value="data.selected"
                                                close
                                                @click="data.select"
                                                @click:close="removeRequiredFields(data.item)"
                                                class="input_chip"
                                            >

                                                {{ data.item.name }}
                                            </v-chip>
                                        </template>
                                        <template v-slot:item="data">
                                            <template v-if="typeof data.item !== 'object'">
                                                <v-list-item-content v-text="data.item"></v-list-item-content>
                                            </template>
                                            <template v-else>
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                </v-list-item-content>
                                            </template>
                                        </template>
                                    </v-autocomplete>
                                </v-col>

                            </v-row>
                        </v-card-text>
                        <v-card-actions class="px-4 pt-10 pb-7">
                            <v-btn type="submit" :disabled="invalid || loading" :loading="loading"
                                   :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                                {{ $t('save') }}
                            </v-btn>
                            <v-btn
                                :disabled="loading"
                                class="ma-1 button_cancel"
                                plain
                                @click="closeDialogAdd"
                            >
                                {{$t('cancel')}}
                            </v-btn>
                            <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                            <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                               :active="loading" class="mx-2"></v-progress-linear>

                        </v-card-actions>

                    </v-card>
                </ValidationObserver>
            </v-form>
        </v-dialog>
    </v-container>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mapGetters} from "vuex";
    import debounce from "lodash/debounce";
    import {buildListArrayFromTreeArray, buildTree} from "@/plugins/functions";

    export default {
        name: 'DealStepForm',
        components: {
            ValidationProvider,
            ValidationObserver
        },
        inject: ['forceRerender'],
        data() {
            return {
                dialogForm: true,
                heading: null,
                progress: 0,
                loading: false,
                tab: 0,
                language: null,
                id: null,
                deal_step: null,
                text: null,
                where_move: [],
                isUpdating: false,
                dealStepItems: [],
                color: '#ff0000',
                finish: null,
                business_process:null,
                businessProcessItems:[],
                admin: null,
                adminItems: [],
                adminSearching: null,
                loadingAdmins: false,
                exist_translations: {},
                all_translations: true,
                isDocumentUpdating: false,
                required_document: [],
                documentTypeItems: [],
                required_filled: false,
                accompany_comment: false,
                deal_statusItems: [],
                deal_status: null,
                required_field: null,
                required_fields: [],
                requiredFieldItems: [],
                isRequiredFieldUpdating: false,
                isRequiredTabUpdating: false,
                required_tabs: [],
                requiredTabItems:[
                    {
                        id: 'deal',
                        name: this.$t('deal.self')
                    },
                    {
                        id: 'tracking',
                        name: this.$t('tracking')
                    },
                    {
                        id: 'order',
                        name: this.$t('order')
                    },
                    {
                        id: 'application',
                        name: this.$t('application')
                    },
                    {
                        id: 'invoice',
                        name: this.$t('invoice')
                    }
                ],
                department: [],
                departmentItems: [],
            }
        },
        computed: {
            ...mapGetters(['listLanguages', 'lang']),
            languages() {
                return this.listLanguages
            },
        },
        async mounted() {
            this.language = this.languages[this.tab]
            await this.getBusinessProcesses();
            await this.getDepartments()
            await this.checkCreate();
            await this.getDocumentType();
            await this.getRequiredFields()
        },
        watch:{
            adminSearching: debounce(function (val) {
                if (val) {
                    this.getAdmins(val)
                }
            }, 500),
        },
        methods: {
            async checkCreate() {
                if (this.$route.name === "deal_step.create") {
                    this.heading = this.$t('deal_step_creation')
                } else {
                    this.heading = this.$t('deal_step_editing')
                    if (this.$route.params.id) {
                        await this.getDealStep()
                        await this.getDealStatuses()
                        await this.getDealSteps()



                    }
                }
            },
            closeDialogAdd(){
                if(window.history.length > 2){
                    this.$router.back()
                }
                else{
                    this.$router.push({
                        name: 'deal_step',
                    })
                }
            },
            setLanguage(val) {
                this.language = this.languages[val]

                this.checkCreate()
            },
            async getDepartments() {
                let params = {};

                await this.$http
                    .get(`admin/department`, {
                        params: params,
                    })
                    .then(res => {
                        let deps = res.body.data;

                        deps = buildTree(deps, 0, 'id', 'parent_id', 'children');
                        deps = buildListArrayFromTreeArray(deps);

                        for (let i in deps) {
                            let department = deps[i];
                            department.name = (' . '.repeat((department.depth ?? 1))) + department.name
                        }



                        this.departmentItems = deps;
                    })
                    .catch(() => {
                        this.departmentItems = [];
                        this.$toastr.error(this.$t('failed_to_get_list_departments'))
                    });
            },
            async getDealStep() {
                var _this = this;
                this.progress = 0
                this.loading = true
                let params = {}
                if (this.language) {
                    params.language = this.language
                }
                await this.$http
                    .get(`admin/deal_step/${this.$route.params.id}`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.deal_step = res.body.data.name
                        this.text = res.body.data.text
                        this.business_process = res.body.data.business_process
                        this.deal_status = res.body.data.deal_status
                        this.admin = res.body.data.admins
                        this.finish = res.body.data.finish
                        this.required_filled = res.body.data.required_filled
                        this.accompany_comment = res.body.data.accompany_comment
                        this.color = res.body.data.color
                        this.where_move = res.body.data.where_move
                        this.department = res.body.data.department
                        this.required_tabs = res.body.data.required_tabs
                        this.required_fields = res.body.data.required_fields
                        this.required_document = res.body.data.required_document
                        this.exist_translations = res.body.data.exist_translations
                        this.all_translations = res.body.data.all_translations
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_deal_step'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async saveDealStep() {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.languages && this.languages[this.tab]) {
                    formData.append('language', this.languages[this.tab])
                }
                if (this.deal_step) {
                    formData.append('deal_step', this.deal_step)
                }
                if (this.text) {
                    formData.append('text', this.text)
                }
                if (this.finish) {
                    formData.append('finish', 1)
                }
                if (this.required_filled) {
                    formData.append('required_filled', 1)
                }
                if (this.accompany_comment) {
                    formData.append('accompany_comment', 1)
                }
                if (this.department.length > 0) {
                    for (let i = 0; i < this.department.length; i++) {
                        formData.append(`department[${i}]`, this.department[i]);
                    }
                }

                if (this.business_process) {
                    if (this.business_process.id) {
                        formData.append('business_process', this.business_process.id)
                    } else {
                        formData.append('business_process', this.business_process)
                    }
                }

                if (this.deal_status) {
                    if (this.deal_status.id) {
                        formData.append('deal_status', this.deal_status.id)
                    } else {
                        formData.append('deal_status', this.deal_status)
                    }
                }

                if (this.color) {
                    formData.append('color', this.color)
                }

                if (this.where_move.length > 0) {
                    for (let i = 0; i < this.where_move.length; i++) {
                        formData.append(`where_move[${i}]`, this.where_move[i]);
                    }
                }
                if (this.required_tabs.length > 0) {
                    for (let i = 0; i < this.required_tabs.length; i++) {
                        formData.append(`required_tabs[${i}]`, this.required_tabs[i]);
                    }
                }
                if (this.required_fields.length > 0) {
                    for (let i = 0; i < this.required_fields.length; i++) {
                        formData.append(`required_fields[${i}]`, this.required_fields[i]);
                    }
                }
                if (this.required_document.length > 0) {
                    for (let i = 0; i < this.required_document.length; i++) {
                        formData.append(`required_document[${i}]`, this.required_document[i]);
                    }
                }

                if (this.admin && this.admin.length > 0) {
                    for (let i in this.admin) {
                        if (this.admin[i].id !== undefined && this.admin[i].id > 0) {
                            formData.append(`admins[${i}]`, this.admin[i].id)
                        }
                    }
                }

                if (this.$route.params.id) {
                    await this.$http
                        .put(`admin/deal_step/${this.$route.params.id}`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('deal_step_has_been_updated'))
                            if (res && res.body && res.body.data && res.body.data.exist_translations) {
                                this.exist_translations = res.body.data.exist_translations
                                this.all_translations = res.body.data.all_translations
                            }
                            this.closeDialogAdd()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('deal_step_has_not_been_updated'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                } else {
                    // Add
                    await this.$http
                        .post('admin/deal_step', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('deal_step_has_been_added'))
                            if (res && res.body && res.body.data && res.body.data.id) {
                                this.exist_translations = res.body.data.exist_translations
                                this.all_translations = res.body.data.all_translations
                            }
                            this.closeDialogAdd()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('deal_step_has_not_been_added'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                }
            },
            async getBusinessProcesses() {
                this.loading = true
                let params = {}
                await this.$http
                    .get("admin/business_process", {
                        params: params,
                    })
                    .then(res => {
                        this.businessProcessItems = res.body.data
                    })
                    .catch(err => {
                        this.businessProcessItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            async getDealSteps() {
                this.loading = true
                let params = {}
                if (this.business_process) {
                    if (this.business_process.id) {
                        params.business_process = this.business_process.id
                    } else {
                        params.business_process = this.business_process
                    }
                }

                await this.$http
                    .get("admin/deal_step", {
                        params: params,
                    })
                    .then(res => {
                        this.dealStepItems = res.body.data
                    })
                    .catch(err => {
                        this.dealStepItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            async getDocumentType() {
                this.loading = true
                let params = {}
                if (this.business_process) {
                    if (this.business_process.id) {
                        params.business_process = this.business_process.id
                    } else {
                        params.business_process = this.business_process
                    }
                }

                await this.$http
                    .get("admin/document_type", {
                        params: params,
                    })
                    .then(res => {
                        this.documentTypeItems = res.body.data
                    })
                    .catch(err => {
                        this.documentTypeItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            clearAdmins() {
                if (!this.adminAdd) {
                    this.adminItems = []
                }
            },
            clearEmptyResponsibleItems(items) {
                if (items && items.length > 0) {
                    this.adminAdd = items.filter(item => typeof item === 'object')
                }
            },
            async getAdmins(str) {
                if (str) {
                    this.loadingAdmins = true
                    let params = {}
                    params.filter = 'search'
                    if (str !== 'undefined') {
                        params.administrator = str
                    }
                    await this.$http
                        .get("admin/admin", {
                            params: params,
                        })
                        .then(res => {
                            this.adminItems = res.body.data
                        })
                        .catch(err => {
                            this.adminItems = []
                        })
                        .finally(end => {
                            this.loadingAdmins = false
                        })
                }
            },

            remove (item) {
                const index = this.where_move.indexOf(item.id)
                if (index >= 0) this.where_move.splice(index, 1)
            },
            removeDepartment (item) {
                const index = this.department.indexOf(item.id)
                if (index >= 0) this.department.splice(index, 1)
            },
            removeDocumentType (item) {
                const index = this.required_document.indexOf(item.id)
                if (index >= 0) this.required_document.splice(index, 1)
            },
            removeRequiredTabs (item) {
                const index = this.required_tabs.indexOf(item.id)
                if (index >= 0) this.required_tabs.splice(index, 1)
            },
            removeRequiredFields (item) {
                const index = this.required_fields.indexOf(item.id)
                if (index >= 0) this.required_fields.splice(index, 1)
            },

            async getDealStatuses() {
                this.loading = true
                let params = {}
                if (this.business_process) {
                    if (this.business_process.id) {
                        params.business_process = this.business_process.id
                    } else {
                        params.business_process = this.business_process
                    }
                }

                await this.$http
                    .get("admin/deal_status", {
                        params: params,
                    })
                    .then(res => {
                        this.deal_statusItems = res.body.data
                    })
                    .catch(err => {
                        this.deal_statusItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            async getRequiredFields() {
                this.loading = true
                let params = {}
                if (this.business_process) {
                    if (this.business_process.id) {
                        params.business_process = this.business_process.id
                    } else {
                        params.business_process = this.business_process
                    }
                }

                await this.$http
                    .get("admin/required_field", {
                        params: params,
                    })
                    .then(res => {
                        this.requiredFieldItems = res.body.data
                    })
                    .catch(err => {
                        this.requiredFieldItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
        }
    }
</script>
